import { ActionIcon, Menu, Text } from "@mantine/core";
import { useAuthState, useSignOut } from "react-firebase-hooks/auth";
import { auth } from "../../../config/firebase";
import { IconPower, IconUser } from "@tabler/icons-react";

const Header = () => {
  const [user] = useAuthState(auth);
  const [signOut] = useSignOut(auth);

  return (
    <div className="flex items-center justify-between h-full px-4">
      <Text>
        <b>Sayer family</b> • Calendrier Val d'Isère
      </Text>
      <Menu withinPortal>
        <Menu.Target>
          <ActionIcon variant="light">
            <IconUser size={18} />
          </ActionIcon>
        </Menu.Target>

        <Menu.Dropdown>
          {user?.email && <Menu.Label>{user.email}</Menu.Label>}
          <Menu.Item
            color="red"
            leftSection={<IconPower size={18} />}
            onClick={signOut}
          >
            Se déconnecter
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </div>
  );
};

export default Header;
