import { Button } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { Event } from "../../../../../config/firebase";
import { FC } from "react";
import EditEventModal from "./EditEventModal";

export interface EditEventButtonProps {
  event: Event;
}

const EditEventButton: FC<EditEventButtonProps> = ({ event }) => {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <EditEventModal opened={opened} close={close} event={event} />
      <Button size="xs" variant="light" onClick={open}>
        Modifier
      </Button>
    </>
  );
};

export default EditEventButton;
