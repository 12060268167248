import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../config/firebase";
import Home from "./Home";
import Auth from "./Auth";
import { Loader } from "@mantine/core";

const Root = () => {
  const [user, loading] = useAuthState(auth);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        <Loader type="bars" />
      </div>
    );
  }

  if (user) {
    return <Home />;
  }

  return <Auth />;
};

export default Root;
