import "dayjs/locale/fr";
import { MantineProvider } from "@mantine/core";
import Root from "./root";
import { DatesProvider } from "@mantine/dates";
import { ModalsProvider } from "@mantine/modals";

function App() {
  return (
    <MantineProvider>
      <ModalsProvider>
        <DatesProvider settings={{ locale: "fr" }}>
          <Root />
        </DatesProvider>
      </ModalsProvider>
    </MantineProvider>
  );
}

export default App;
