import {
  Button,
  Group,
  Modal,
  MultiSelect,
  Stack,
  TextInput,
  Textarea,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { Timestamp, addDoc, collection } from "firebase/firestore";
import { Event, auth, db, flats } from "../../../../../config/firebase";
import dayjs from "dayjs";
import { useAuthState } from "react-firebase-hooks/auth";
import { FC } from "react";

export interface NewEventModalProps {
  opened: boolean;
  close: () => void;
  defaultDate: Date;
}

const NewEventModal: FC<NewEventModalProps> = ({
  opened,
  close,
  defaultDate,
}) => {
  const [user] = useAuthState(auth);
  const form = useForm({
    initialValues: {
      dates: [null, null],
      title: "",
      description: "",
      flats: [],
    },

    validate: {
      dates: (value) =>
        value[0] && value[1] ? null : "Vous devez choisir une date",
      title: (value) =>
        value.length ? null : "Le titre ne peut pas être vide",
    },
  });

  const [loading, { open: start, close: stop }] = useDisclosure(false);

  const handleClose = () => {
    close();
    form.reset();
  };

  return (
    <Modal
      opened={opened}
      onClose={handleClose}
      title="Nouvelle réservation"
      centered={true}
    >
      <form
        onSubmit={form.onSubmit((values) => {
          if (user?.email) {
            start();
            addDoc<Event, Event>(collection(db, "events"), {
              startDate: dayjs(values.dates[0]).format("YYYY-MM-DD"),
              endDate: dayjs(values.dates[1]).format("YYYY-MM-DD"),
              title: values.title,
              description: values.description,
              flats: values.flats,
              createdAt: Timestamp.now(),
              createdBy: user.email,
            })
              .then(handleClose)
              .finally(stop);
          }
        })}
      >
        <Stack>
          <DatePickerInput
            defaultDate={defaultDate}
            allowSingleDateInRange
            level="month"
            withAsterisk
            disabled={loading}
            valueFormat="ddd D MMM YYYY"
            type="range"
            label="Dates"
            placeholder="sam. 23 déc. 2023 - sam. 6 janv. 2024"
            classNames={{
              placeholder: "text-[#adb5bd]",
            }}
            {...form.getInputProps("dates")}
          />
          <TextInput
            withAsterisk
            disabled={loading}
            label="Titre"
            placeholder="Famille Sayer"
            classNames={{
              input: "placeholder:text-[#adb5bd]",
            }}
            {...form.getInputProps("title")}
          />
          <Textarea
            label="Description"
            disabled={loading}
            placeholder="Vacances de Noël"
            autosize
            minRows={2}
            {...form.getInputProps("description")}
          />
          <MultiSelect
            disabled={loading}
            label="Appartements"
            placeholder={form.values.flats.length ? "" : "33"}
            data={flats}
            {...form.getInputProps("flats")}
          />
          <Group justify="space-between" wrap="nowrap">
            <div />
            <Group wrap="nowrap">
              <Button
                onClick={handleClose}
                variant="default"
                disabled={loading}
              >
                Annuler
              </Button>
              <Button type="submit" loading={loading}>
                Valider
              </Button>
            </Group>
          </Group>
        </Stack>
      </form>
    </Modal>
  );
};

export default NewEventModal;
