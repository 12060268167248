import { AppShell } from "@mantine/core";
import Header from "./Header";
import Main from "./Main";

const Home = () => {
  return (
    <AppShell header={{ height: 60 }} padding="md">
      <AppShell.Header>
        <Header />
      </AppShell.Header>

      <AppShell.Main>
        <Main />
      </AppShell.Main>
    </AppShell>
  );
};

export default Home;
