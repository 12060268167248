import {
  Button,
  Group,
  Modal,
  MultiSelect,
  Stack,
  Text,
  TextInput,
  Textarea,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import {
  Timestamp,
  arrayUnion,
  deleteField,
  updateDoc,
} from "firebase/firestore";
import { Event, auth, flats } from "../../../../../../config/firebase";
import dayjs from "dayjs";
import { useAuthState } from "react-firebase-hooks/auth";
import { FC } from "react";
import { modals } from "@mantine/modals";
import { omit } from "lodash";

export interface EditEventModalProps {
  event: Event;
  opened: boolean;
  close: () => void;
}

const EditEventModal: FC<EditEventModalProps> = ({ event, opened, close }) => {
  const [user] = useAuthState(auth);
  const form = useForm({
    initialValues: {
      dates: [
        event.startDate ? dayjs(event.startDate).toDate() : null,
        event.endDate ? dayjs(event.endDate).toDate() : null,
      ],
      title: event.title ?? "",
      description: event.description ?? "",
      flats: event.flats ?? [],
    },

    validate: {
      dates: (value) =>
        value[0] && value[1] ? null : "Vous devez choisir une date",
      title: (value) =>
        value.length ? null : "Le titre ne peut pas être vide",
    },
  });

  const [loading, { open: start, close: stop }] = useDisclosure(false);

  const handleClose = () => {
    close();
    form.reset();
  };

  const openDeleteModal = () =>
    modals.openConfirmModal({
      title: "Supprimer la réservation",
      centered: true,
      children: (
        <Text size="sm">
          Êtes-vous sûr de vouloir supprimer la réservation?
        </Text>
      ),
      labels: { confirm: "Supprimer", cancel: "Annuler" },
      confirmProps: { color: "red" },
      onConfirm: () => {
        if (event.ref && user?.email) {
          start();
          updateDoc<Event, Event>(event.ref, {
            history: arrayUnion(omit(event, ["id", "ref", "history"])),
            deletedAt: Timestamp.now(),
            deletedBy: user.email,
          })
            .then(handleClose)
            .finally(stop);
        }
      },
    });

  const openRestoreModal = () =>
    modals.openConfirmModal({
      title: "Restaurer la réservation",
      centered: true,
      children: (
        <Text size="sm">
          Êtes-vous sûr de vouloir restaurer la réservation?
        </Text>
      ),
      labels: { confirm: "Restaurer", cancel: "Annuler" },
      confirmProps: { color: "red" },
      onConfirm: () => {
        if (event.ref && user?.email) {
          start();
          updateDoc<Event, Event>(event.ref, {
            history: arrayUnion(omit(event, ["id", "ref", "history"])),
            deletedAt: deleteField(),
            deletedBy: deleteField(),
          })
            .then(handleClose)
            .finally(stop);
        }
      },
    });

  return (
    <Modal
      opened={opened}
      onClose={handleClose}
      title="Modifier la réservation"
      centered={true}
    >
      <form
        onSubmit={form.onSubmit((values) => {
          if (event.ref && user?.email) {
            start();
            updateDoc<Event, Event>(event.ref, {
              history: arrayUnion(omit(event, ["id", "ref", "history"])),
              startDate: dayjs(values.dates[0]).format("YYYY-MM-DD"),
              endDate: dayjs(values.dates[1]).format("YYYY-MM-DD"),
              title: values.title,
              description: values.description,
              flats: values.flats,
              updatedAt: Timestamp.now(),
              updatedBy: user.email,
            })
              .then(handleClose)
              .finally(stop);
          }
        })}
      >
        <Stack>
          <DatePickerInput
            level="month"
            withAsterisk
            allowSingleDateInRange
            disabled={loading}
            valueFormat="ddd D MMM YYYY"
            type="range"
            label="Dates"
            placeholder="sam. 23 déc. 2023 - sam. 6 janv. 2024"
            classNames={{
              placeholder: "text-[#adb5bd]",
            }}
            {...form.getInputProps("dates")}
          />
          <TextInput
            withAsterisk
            disabled={loading}
            label="Titre"
            placeholder="Famille Sayer"
            {...form.getInputProps("title")}
          />
          <Textarea
            label="Description"
            disabled={loading}
            placeholder="Vacances de Noël"
            autosize
            minRows={2}
            {...form.getInputProps("description")}
          />
          <MultiSelect
            disabled={loading}
            label="Appartements"
            placeholder={form.values.flats.length ? "" : "33"}
            data={flats}
            classNames={{
              inputField: "placeholder:text-[#adb5bd]",
            }}
            {...form.getInputProps("flats")}
          />
          <Group justify="space-between" wrap="nowrap">
            {event.deletedAt ? (
              <Button
                variant="light"
                color="red"
                onClick={openRestoreModal}
                disabled={loading}
              >
                Restaurer
              </Button>
            ) : (
              <Button
                variant="light"
                color="red"
                onClick={openDeleteModal}
                disabled={loading}
              >
                Supprimer
              </Button>
            )}
            <Group wrap="nowrap">
              <Button
                onClick={handleClose}
                variant="default"
                disabled={loading}
              >
                Annuler
              </Button>
              <Button type="submit" loading={loading}>
                Modifier
              </Button>
            </Group>
          </Group>
        </Stack>
      </form>
    </Modal>
  );
};

export default EditEventModal;
