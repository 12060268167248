import { collection } from "firebase/firestore";
import { useState } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { Event, db, firestoreConverter } from "../../../config/firebase";
import { Stack } from "@mantine/core";
import NewEventButton from "./NewEventButton";
import EventsList from "./EventsList";
import CalendarCard from "./CalendarCard";

const Main = () => {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [calendarDate, setCalendarDate] = useState<Date>(new Date());
  const [events, loading] = useCollectionData<Event>(
    collection(db, "events").withConverter(firestoreConverter),
  );

  return (
    <Stack align="center">
      <NewEventButton defaultDate={calendarDate} />
      <CalendarCard
        events={events}
        loading={loading}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        calendarDate={calendarDate}
        setCalendarDate={setCalendarDate}
      />
      <EventsList selectedDate={selectedDate} events={events} />
    </Stack>
  );
};

export default Main;
