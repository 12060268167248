import { Button } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconPlus } from "@tabler/icons-react";
import { FC } from "react";
import NewEventModal from "./NewEventModal";

export interface NewEventButtonProps {
  defaultDate: Date;
}

const NewEventButton: FC<NewEventButtonProps> = ({ defaultDate }) => {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <NewEventModal opened={opened} close={close} defaultDate={defaultDate} />
      <Button leftSection={<IconPlus size={18} />} onClick={open}>
        Nouvelle réservation
      </Button>
    </>
  );
};

export default NewEventButton;
