import { Calendar } from "@mantine/dates";
import dayjs from "dayjs";
import { FC } from "react";
import { Event } from "../../../../config/firebase";
import { Badge, Card, LoadingOverlay } from "@mantine/core";

export interface CalendarCardProps {
  events?: Event[];
  loading: boolean;
  selectedDate: Date;
  setSelectedDate: React.Dispatch<React.SetStateAction<Date>>;
  calendarDate: Date;
  setCalendarDate: React.Dispatch<React.SetStateAction<Date>>;
}

const CalendarCard: FC<CalendarCardProps> = ({
  events,
  loading,
  selectedDate,
  setSelectedDate,
  calendarDate,
  setCalendarDate,
}) => {
  return (
    <Card withBorder>
      <LoadingOverlay
        visible={loading}
        zIndex={1}
        overlayProps={{ blur: 2 }}
        loaderProps={{
          type: "bars",
        }}
      />
      <Calendar
        getDayProps={(date) => ({
          selected: dayjs(date).isSame(selectedDate, "date"),
          onClick: () => setSelectedDate(date),
        })}
        level="month"
        onLevelChange={() => {
          setSelectedDate(new Date());
          setCalendarDate(new Date());
        }}
        date={calendarDate}
        onDateChange={setCalendarDate}
        renderDay={(date) => {
          const day = date.getDate();

          if (dayjs(date).isSame(selectedDate, "date")) {
            return <div>{day}</div>;
          }

          const hasEvent = events?.some((event) => {
            const formattedDate = dayjs(date).format("YYYY-MM-DD");

            return (
              !event?.deletedAt &&
              event?.startDate &&
              event?.endDate &&
              formattedDate >= event.startDate &&
              formattedDate <= event.endDate
            );
          });

          if (loading || !hasEvent) {
            return <div>{day}</div>;
          }

          return (
            <Badge autoContrast circle size="lg" variant="light">
              <div>{day}</div>
            </Badge>
          );
        }}
      />
    </Card>
  );
};

export default CalendarCard;
