import dayjs from "dayjs";
import { FC } from "react";
import { Event } from "../../../../config/firebase";
import {
  Badge,
  Card,
  Divider,
  Group,
  Indicator,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import { orderBy } from "lodash";
import EditEventButton from "./EditEventButton";

export interface EventsListProps {
  events?: Event[];
  selectedDate: Date;
}

const EventsList: FC<EventsListProps> = ({ events, selectedDate }) => {
  const selectedEvents = orderBy(
    events?.filter((event) => {
      const formattedDate = dayjs(selectedDate).format("YYYY-MM-DD");

      return (
        event.startDate &&
        event.endDate &&
        formattedDate >= event.startDate &&
        formattedDate <= event.endDate
      );
    }),
    ["deletedAt", "startDate", "endDate"],
    ["desc", "asc", "asc"],
  );

  return (
    <>
      <Text>
        {selectedDate &&
          dayjs(selectedDate).locale("fr").format("dddd D MMMM YYYY")}{" "}
        :
      </Text>
      {selectedEvents.length ? (
        selectedEvents.map((event) => {
          const startDate = dayjs(event.startDate);
          const endDate = dayjs(event.endDate);

          return (
            <Indicator
              key={event.id}
              label="Supprimée"
              size={20}
              color="red"
              disabled={!event.deletedAt}
              position="top-center"
              className="opacity-75"
            >
              <Card withBorder className="max-w-lg">
                <Stack gap="xs">
                  <Stack gap="xs" opacity={event.deletedAt ? 0.5 : 1}>
                    <Text c="dimmed" fz="sm">
                      {startDate.locale("fr").format("ddd D MMM YYYY")} →{" "}
                      {endDate.locale("fr").format("ddd D MMM YYYY")}
                    </Text>
                    <Group wrap="nowrap">
                      <Text>{event.title}</Text>
                      {event.flats?.map((flat) => {
                        return (
                          <div key={flat}>
                            <Tooltip label={`Appartement ${flat}`}>
                              <Badge variant="light" color="red">
                                Apt {flat}
                              </Badge>
                            </Tooltip>
                          </div>
                        );
                      })}
                    </Group>
                    {event.description && (
                      <Text c="dimmed" fz="sm">
                        {event.description}
                      </Text>
                    )}
                    <Divider />
                    {!event.deletedAt && (
                      <Text c="dimmed" fz="xs">
                        Ajouté par {event.createdBy} le{" "}
                        {event.createdAt
                          ? dayjs(event.createdAt?.toDate())
                              .locale("fr")
                              .format("D MMM YYYY à HH:mm")
                          : ""}
                      </Text>
                    )}
                    {!event.deletedAt && event.updatedAt && event.updatedBy && (
                      <Text c="dimmed" fz="xs">
                        Modifié par {event.updatedBy} le{" "}
                        {event.updatedAt
                          ? dayjs(event.updatedAt?.toDate())
                              .locale("fr")
                              .format("D MMM YYYY à HH:mm")
                          : ""}
                      </Text>
                    )}
                    {event.deletedAt && event.deletedBy && (
                      <Text c="dimmed" fz="xs">
                        Supprimé par {event.deletedBy} le{" "}
                        {event.deletedAt
                          ? dayjs(event.deletedAt?.toDate())
                              .locale("fr")
                              .format("D MMM YYYY à HH:mm")
                          : ""}
                      </Text>
                    )}
                  </Stack>
                  <EditEventButton event={event} />
                </Stack>
              </Card>
            </Indicator>
          );
        })
      ) : (
        <Text c="dimmed" fz="sm">
          Aucune réservation pour ce jour
        </Text>
      )}
    </>
  );
};

export default EventsList;
